import React from 'react';
import '../assets/styles/Navbar.css';
import logo from '../assets/images/logo.svg';
import {useNavigate} from 'react-router-dom';
// import {logout, useAuthLocalStorage } from '../utils/Storage';

function Navbar() {
    const navigate = useNavigate();
    // const authToken = useAuthLocalStorage();

    // const handleLoginLogout = () => {
    //     if (authToken) {
    //         logout();
    //         navigate('/login');
    //     } else {
    //         navigate('/login');
    //     }
    // };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <nav className="navbar">
            <div className="navbar-logo-container" onClick={handleLogoClick}>
                <img src={logo} alt="Logo" className="navbar-logo"/>
            </div>
            {/* <h1 onClick={handleLoginLogout}>
                {authToken ? 'Cerrar Sesión' : 'Iniciar Sesión'}
            </h1> */}
            
        </nav>
    );
}

export default Navbar;
