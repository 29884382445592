import React, { useState, useEffect } from 'react';
import { Modal, Input, Button } from 'antd';
import { generatePDF } from '../utils/generatePDF';
import { DownloadOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const InformeModal = ({ isVisible, onClose, solicitud, observaciones, diagnostico, images}) => {
    const [editedSolicitud, setEditedSolicitud] = useState(solicitud);
    const [editedObservaciones, setEditedObservaciones] = useState(observaciones);
    const [editedDiagnostico, setEditedDiagnostico] = useState(diagnostico);

    useEffect(() => {
        setEditedSolicitud(solicitud);
        setEditedObservaciones(observaciones);
        setEditedDiagnostico(diagnostico);
    }, [solicitud, observaciones, diagnostico]);

    const handleSave = () => {
        localStorage.setItem('solicitud', editedSolicitud);
        localStorage.setItem('observaciones', editedObservaciones);
        localStorage.setItem('diagnostico', editedDiagnostico);
        // onSave({
        //     solicitud: editedSolicitud,
        //     observaciones: editedObservaciones,
        //     diagnostico: editedDiagnostico
        // });
        onClose();
    };

    return (
        <Modal
            title="Vista previa de informe"
            open={isVisible}
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancelar
                </Button>,
                <Button
                    className="generate-button"
                    onClick={() => {
                        generatePDF(images);
                        handleSave();
                    }}
                >
                    <DownloadOutlined />
                    Descargar Informe
                </Button>
            ]}
            width={900}
        >
            <div style={{ marginBottom: 10 }}>
                <h3>Solicitud:</h3>
                <TextArea
                    value={editedSolicitud}
                    onChange={(e) => setEditedSolicitud(e.target.value)}
                    rows={3}
                />
            </div>
            <div style={{ marginBottom: 10 }}>
                <h3>Observaciones:</h3>
                <TextArea
                    value={editedObservaciones}
                    onChange={(e) => setEditedObservaciones(e.target.value)}
                    rows={4}
                />
            </div>
            <div>
                <h3>Diagnóstico:</h3>
                <TextArea
                    value={editedDiagnostico}
                    onChange={(e) => setEditedDiagnostico(e.target.value)}
                    rows={4}
                />
            </div>
        </Modal>
    );
};

export default InformeModal;

