import {getTokenFromLocalStorage} from "./LocalStorageServices";

const REACT_APP_BFF_URL = process.env.REACT_APP_BFF_URL;

export const askVertex = async (prompt) => {
    localStorage.removeItem('vertexResponse');

    const response = await fetch(`${REACT_APP_BFF_URL}/ask-vertex`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            //get authorization token from cookie
            Authorization: `Bearer ${getTokenFromLocalStorage('jwt_token')}`,
        },
        body: JSON.stringify({question: prompt}),
    });
    return response.json();

};