import { PDFDocument, rgb } from 'pdf-lib';
import { saveAs } from 'file-saver';
import samplePdf from '../assets/PDF/informe2.pdf';

export async function generatePDF(images) {
    try {
        // Recuperar el objeto formValues desde localStorage
        const formValues = JSON.parse(localStorage.getItem('formValues')) || {};
        const fechaStr = localStorage.getItem('fecha'); // Recuperar la fecha desde localStorage
        const fecha = fechaStr ? new Date(fechaStr) : new Date(); // Si no hay fecha, usar la fecha actual

        // Formatear la fecha en formato DD/MM/YYYY
        const formattedDate = `${fecha.getDate().toString().padStart(2, '0')}/${(fecha.getMonth() + 1).toString().padStart(2, '0')}/${fecha.getFullYear()}`;

        // Usar los valores de formValues o valores predeterminados
        const nombreAnimal = formValues.nombreAnimal || 'Nombre Animal';
        const edadAnimal = formValues.edad || 'Edad';
        const udEdad = formValues.edadUnidad || '';
        const sexo = formValues.sexo || 'Sexo';
        const especieAnimal = formValues.especie || 'Especie Animal';
        const propietario = formValues.propietario || 'Propietario';
        const raza = formValues.raza || 'Raza';
        const profesional = formValues.especialista || 'Profesional';
        const solicitud = localStorage.getItem('solicitud') || '';
        const observacion = localStorage.getItem('observaciones') || '';
        const diagnostico = localStorage.getItem('diagnostico') || '';

        const existingPdfBytes = await fetch(samplePdf).then((res) => {
            if (!res.ok) {
                throw new Error('Failed to fetch the PDF');
            }
            return res.arrayBuffer();
        });

        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        const pages = pdfDoc.getPages();
        const secondPage = pages[1];
        const thirdPage = pages[2];

        const edadAnimalText = edadAnimal.toString();
        const edadAnimalLength = edadAnimalText.length;

        const charWidth1 = 6; // Aproximado ancho de cada carácter
        const edadAnimalWidth = edadAnimalLength * charWidth1;

        // Función para dividir el texto en varias líneas según el ancho máximo
        const breakTextIntoLines = (text, maxWidth, fontSize, charWidth) => {
            const words = text.split(' ');
            let lines = [];
            let currentLine = '';

            words.forEach((word) => {
                const potentialLine = currentLine.length > 0 ? `${currentLine} ${word}` : word;
                const potentialLineWidth = potentialLine.length * charWidth;

                if (potentialLineWidth > maxWidth) {
                    lines.push(currentLine);
                    currentLine = word;
                } else {
                    currentLine = potentialLine;
                }
            });

            if (currentLine.length > 0) {
                lines.push(currentLine);
            }

            return lines;
        };

        // Ajustar observaciones en varias líneas
        const maxWidth = 540; // Ancho máximo en píxeles
        const fontSize = 11; // Tamaño de fuente
        const charWidth = 6; // Ancho aproximado por carácter
        const observationLines = breakTextIntoLines(observacion, maxWidth, fontSize, charWidth);

        // Posición inicial para las observaciones
        let yPosition = 470;

        // Dibujar cada línea de la observación
        observationLines.forEach((line) => {
            secondPage.drawText(`• ${line}`, { // Agrega el puntito adelante
                x: 90,
                y: yPosition,
                size: fontSize,
                color: rgb(0, 0, 0)
            });
            yPosition -= 13; // Ajustar el espaciado entre líneas
        });

        // Dibujar otros campos en la segunda página
        secondPage.drawText(formattedDate, { x: 461, y: 638, size: 11, color: rgb(0, 0, 0) });
        secondPage.drawText(nombreAnimal, { x: 149, y: 620, size: 11, color: rgb(0, 0, 0) });
        secondPage.drawText(edadAnimal, { x: 296, y: 620, size: 11, color: rgb(0, 0, 0) });
        secondPage.drawText(udEdad, { x: 296 + edadAnimalWidth + 2, y: 620, size: 11, color: rgb(0, 0, 0) });
        secondPage.drawText(sexo, { x: 427, y: 602, size: 11, color: rgb(0, 0, 0) });
        secondPage.drawText(especieAnimal, { x: 141, y: 602, size: 11, color: rgb(0, 0, 0) });
        secondPage.drawText(propietario, { x: 166, y: 583, size: 11, color: rgb(0, 0, 0) });
        secondPage.drawText(raza, { x: 296, y: 602, size: 11, color: rgb(0, 0, 0) });
        secondPage.drawText(profesional, { x: 341, y: 583, size: 11, color: rgb(0, 0, 0) });
        secondPage.drawText(solicitud, { x: 143, y: 540, size: 11, color: rgb(0, 0, 0) });
        const diagnosticoLines = diagnostico.split('\n');

        // Posición inicial para el diagnóstico
        let diagnosticoYPosition = 300;

        // Dibujar cada línea del diagnóstico con el símbolo '•', solo si la línea no está vacía
        diagnosticoLines.forEach((line) => {
            if (line.trim() !== '') { // Verifica que la línea no esté vacía (elimina espacios en blanco)
                secondPage.drawText(`• ${line}`, { // Agrega el puntito delante
                    x: 90,
                    y: diagnosticoYPosition,
                    size: 11,
                    color: rgb(0, 0, 0),
                });
                diagnosticoYPosition -= 13; // Ajustar el espaciado entre líneas
            }
        });

        // Función para redimensionar la imagen manteniendo su proporción
        const resizeImage = (img) => {
            const maxWidthImage = 500;
            const maxHeightImage = 400;

            let newWidth = img.width;
            let newHeight = img.height;

            const widthRatio = maxWidthImage / img.width;
            const heightRatio = maxHeightImage / img.height;
            const ratio = Math.min(widthRatio, heightRatio);

            if (ratio < 1) {
                newWidth = img.width * ratio;
                newHeight = img.height * ratio;
            }

            return { newWidth, newHeight };
        };

        // Función para cargar imagen dependiendo de su formato
        const loadImage = async (imageData) => {
            if (imageData.startsWith('data:image/jpeg;base64,')) {
                return await pdfDoc.embedJpg(imageData);
            } else if (imageData.startsWith('data:image/png;base64,')) {
                return await pdfDoc.embedPng(imageData);
            } else {
                throw new Error('Unsupported image format.');
            }
        };

        // Dibujar imágenes en la tercera página
        if (images.length > 0) {
            const img = await loadImage(images[0]);
            const { newWidth, newHeight } = resizeImage(img);

            thirdPage.drawImage(img, {
                x: (thirdPage.getWidth() - newWidth) / 2,
                y: (thirdPage.getHeight() - newHeight) / 2,
                width: newWidth,
                height: newHeight,
            });
        }

        for (let i = 1; i < images.length; i++) {
            const img = await loadImage(images[i]);
            const { newWidth, newHeight } = resizeImage(img);

            const [copiedPage] = await pdfDoc.copyPages(pdfDoc, [2]);
            pdfDoc.insertPage(3 + i - 1, copiedPage);

            copiedPage.drawImage(img, {
                x: (copiedPage.getWidth() - newWidth) / 2,
                y: (copiedPage.getHeight() - newHeight) / 2,
                width: newWidth,
                height: newHeight,
            });
        }

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        saveAs(blob, 'InformeConImagenes.pdf');

    } catch (error) {
        console.error('Error generating PDF:', error);
        alert('Error generating PDF. Please check the console for more details.');
    }
}