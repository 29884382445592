import React from 'react';
import { Col, Row } from 'antd';
import '../assets/styles/MainPage.css';
import ovejero from '../assets/images/ovejero.webp';
import logo2 from '../assets/images/logo2.svg';
import seminario from '../assets/images/seminario.webp';
import arrow from '../assets/images/arrow.gif';
// import Counter from '../components/Counter';

const LandingPage = () => {
    // const navigate = useNavigate();

    // const handleLogin = () => {
    //     navigate('/login');
    // };

    return (
        <div className="body">
            <Row className="content">
                <Col lg={10} sm={24} xs={24} className="leftside">
                    <div>
                        <img src={ovejero} alt="ovejero" className="dogimage" />
                    </div>
                </Col>
                <Col lg={14} sm={24} xs={24} className="rightside">
                    <Col span={24}>
                        <Col span={24} className="rightside-container">
                            <div className="logo-container">
                                <img src={logo2} alt="Logo DiagnovetAI" className="logo" />
                            </div>
                            <h1 className="subtitle">La herramienta perfecta para el diagnóstico de imágenes veterinarias</h1>
                            <Row justify="left" align="middle" className='separation'>
                                <h2 className="subtitle2">¿Quieres ser parte de los primeros usuarios?</h2>
                                <div className="button-container">
                                    <a href="https://forms.gle/hUqN62UEtfw7XD1F6" target="_blank" rel="noopener noreferrer">
                                        <button className="button">Registrate aquí</button>
                                    </a>
                                </div>
                            </Row>
                        </Col>
                    </Col>
                </Col>
                <Col span={24} className="one-secondRow">
                    <Row justify="center" className="image-wrapper">
                        <img src={arrow} alt="Flecha izquierda" className=" left-arrow arrow-gif" />
                        <a href="https://forms.gle/hmQs6VKKAe9wur6w6" target="_blank" rel="noopener noreferrer">
                            <img src={seminario} alt="seminario" className="imgseminario" />
                        </a>
                        <img src={arrow} alt="Flecha derecha" className=" right-arrow arrow-gif" />
                    </Row>
                </Col>
                <Col span={24} className="secondRow">
                    <Row justify={'center'}>
                        <Col span={24}>
                            <h2 className='bluetitle'>Diagnovet AI es tu asistente para un mejor cuidado de tus pacientes</h2>
                        </Col>
                        <Col span={18} className='spacing'>
                            <h3 className="whitesubtitle ">
                                Diagnovet AI te ayuda a optimizar tu tiempo, mejorar la eficiencia y enfocarte en el bienestar de tus pacientes, automatizando la generación de reportes con la ayuda de la inteligencia artificial.                            </h3>
                        </Col>
                        <Col span={24} className="benefits-container spacing">
                            <ul className="benefits-list">
                                <li><span className="checkmark">✔</span> Libérate de tareas repetitivas</li>
                                <li><span className="checkmark">✔</span> Ahórrate tiempo valioso</li>
                                <li><span className="checkmark">✔</span> Genera reportes profesionales sin esfuerzo</li>
                                <li><span className="checkmark">✔</span> Atiende a más pacientes</li>
                                <li><span className="checkmark">✔</span> Usa tu tiempo para casos más complejos</li>
                            </ul>
                        </Col>
                        {/* <Col span={24}>
                            <div className="video-container">
                                <video className="video" controls>
                                    <source src={videoSample} type="video/mp4" />
                                    Tu navegador no soporta el elemento de video.
                                </video>
                            </div>
                        </Col> */}
                    </Row>
                </Col>
                {/* <Col span={24} className="thirdRow">
                    <Row justify={'center'}>
                        <Col span={24}>
                            <h2 className='bluetitle'>AI diseñada para veterinarios</h2>
                        </Col>
                        <Col span={18}>
                            <p className="item2">
                                ¡Fácil de usar, sube tu imagen y listo!
                            </p>
                            <Row justify={'space-around'} className="itemsRow">
                                <Col span={6}>
                                    <p className="item">
                                        Resultados Instantáneos
                                    </p>
                                </Col>
                                <Col span={6}>
                                    <p className="item">
                                        Todos los Formatos de Imagen
                                    </p>
                                    <p className="subitem">
                                        DICOM / JPG / PNG
                                    </p>
                                </Col>
                                <Col span={6}>
                                    <p className="item">
                                        Informes Automáticos
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={24} className="forthRow">
                    <Row justify={'center'}>
                        <Col span={24}>
                            <h2 className='bluetitle'>Regiones anatómicas procesadas</h2>
                        </Col>
                        <Col lg={18} sm={22} xs={22}>
                            <Row justify={'space-around'} className="itemsRow2">
                                <Col span={6}>
                                    <div className="circle"></div>
                                    <p className="item3">
                                        Abdomen
                                    </p>
                                    <p className="subitem2">
                                        <span className="uppercase">13 patrones</span> detectados
                                    </p>
                                    <p className="subitem3">
                                        <span className="uppercase">3 sugerencias</span> de diagnóstico diferencial
                                    </p>
                                </Col>
                                <Col span={6}>
                                    <div className="circle"></div>
                                    <p className="item3">
                                        Tórax
                                    </p>
                                    <p className="subitem2">
                                        <span className="uppercase">13 patrones</span> detectados
                                    </p>
                                    <p className="subitem3">
                                        <span className="uppercase">3 sugerencias</span> de diagnóstico diferencial
                                    </p>
                                </Col>
                                <Col span={6}>
                                    <div className="circle"></div>
                                    <p className="item3">
                                        Displasia de cadera
                                    </p>
                                    <p className="subitem2">
                                        <span className="uppercase">13 patrones</span> detectados
                                    </p>
                                    <p className="subitem3">
                                        <span className="uppercase">3 sugerencias</span> de diagnóstico diferencial
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={24} className="fifthRow">
                    <Row justify={'center'}>
                        <Col span={24}>
                            <h2 className='bluetitle'>DiagnovetAI en números</h2>
                        </Col>
                        <Col lg={18} sm={22} xs={22}>
                            <Row justify={'space-around'} className="itemsRow3">
                                <Col span={6}>
                                    <div className="counter-container">
                                        <Counter endNumber={1000} />
                                    </div>
                                    <p className="item">
                                        Radiografías analizadas
                                    </p>
                                </Col>
                                <Col span={6}>
                                    <div className="counter-container">
                                        <Counter endNumber={500} />
                                    </div>
                                    <p className="item">
                                        Usuarios
                                    </p>
                                </Col>
                                <Col span={6}>
                                    <div className="counter-container">
                                        <Counter endNumber={1200} />
                                    </div>
                                    <p className="item">
                                        Radiografías analizadas
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col> */}
            </Row>

        </div>
    );
};

export default LandingPage;
