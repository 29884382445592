import React from 'react';
import {Col, Input, Row} from 'antd';
import {useNavigate} from 'react-router-dom';
import '../assets/styles/LoginPage.css';
import collie from '../assets/images/collie.webp';

const SignupPage = () => {
    const navigate = useNavigate();

    const handleSignUp = () => {
        navigate('/login');
    };

    return (
        <div className="body">
            <Row className="content">
                <Col lg={10} sm={24} xs={24} className="leftside2">
                    <div>
                        <img src={collie} alt="ovejero" className="dogimage3"/>
                    </div>
                </Col>
                <Col lg={14} sm={24} xs={24} className="rightside">
                    <Col span={24}>
                        <Col span={24} className="rightside-container3">
                            <div className="logo-container">
                                <p className="bluetitle decrease">Registrarse</p>
                            </div>
                            <div className="input-container">
                                <Input placeholder="Usuario" className="loginInput"/>
                            </div>
                            <div className="input-container">
                                <Input placeholder="Correo" className="loginInput"/>
                            </div>
                            <div className="input-container">
                                <Input placeholder="Contraseña" className="loginInput"/>
                            </div>
                            <div className="input-container">
                                <Input placeholder="Repetir Contraseña" className="loginInput"/>
                            </div>
                            <div className="button-container2">
                                <span
                                    className="login-redirect"
                                >
                                    ¿Olvidaste tu contraseña?
                                </span>
                                <span
                                    className="login-redirect"
                                    onClick={handleSignUp}
                                >
                                    ¿No tienes una cuenta?
                                </span>
                            </div>
                            <div className="button-container">
                                <button className="button2" onClick={handleSignUp}>
                                    Registrarse
                                </button>
                            </div>
                        </Col>
                    </Col>
                </Col>
            </Row>
        </div>
    );
};

export default SignupPage;
