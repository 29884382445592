import React, { useEffect } from 'react';
import { Button, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import golden from '../assets/images/golden.webp';
import '../assets/styles/LoginPage.css';
import { useAuthLocalStorage } from '../utils/Storage';
import { googleLogin } from '../services/AuthServices';

const LoginPage = () => {
    const navigate = useNavigate();
    const token = useAuthLocalStorage();

    useEffect(() => {
        if (token) {
            navigate('/dashboard');
        }
    }, [navigate, token]); // Agregamos 'token' al array de dependencias

    return (
        <div className="body">
            <Row className="content">
                <Col lg={10} sm={24} xs={24} className="newleftside">
                    <div>
                        <img src={golden} alt="ovejero" className="dogimage2" />
                    </div>
                </Col>
                <Col lg={14} sm={24} xs={24} className="rightside">
                    <Col span={24}>
                        <Col span={24} className="rightside-container2">
                            <div className="logo-container spacing">
                                <p className="bluetitle decrease">Inicia Sesión</p>
                            </div>
                            <div className="button-container">
                                <Button
                                    className="google-login-button"
                                    onClick={googleLogin} // Asegúrate de que 'googleLogin' está correctamente importado y definido
                                >
                                    <img
                                        src="https://developers.google.com/identity/images/g-logo.png"
                                        alt="Google Logo"
                                        className="google-logo"
                                    />
                                    <span className="google-login-text">Iniciar con Google</span>
                                </Button>
                            </div>
                        </Col>
                    </Col>
                </Col>
            </Row>
        </div>
    );
};

export default LoginPage;
