import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Col, Image, Input, Row, Slider, Collapse, Select, message, Popconfirm } from 'antd';
import { ZoomInOutlined, ZoomOutOutlined, PlusCircleOutlined, ArrowRightOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/Analisis.css';
import { askVertex } from '../services/VertexServices';
import InformeModal from '../components/InformeModal';

const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

(function () {
    const originalSetItem = localStorage.setItem;
    localStorage.setItem = function (key, value) {
        const event = new Event('vertexResponseChange');
        event.key = key;
        event.newValue = value;
        window.dispatchEvent(event);
        originalSetItem.apply(this, arguments);
    };
})();

const Analisis = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [zoom, setZoom] = useState(1);
    const [invert, setInvert] = useState(0);
    const [contrast, setContrast] = useState(100);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
    const [vertexResponse, setVertexResponse] = useState('');
    const [images, setImages] = useState([]);
    const [regenerateCount, setRegenerateCount] = useState(() => {
        const savedCount = localStorage.getItem('regenerateCount');
        return savedCount ? parseInt(savedCount, 10) : 0;
    });
    const [selectedStructure, setSelectedStructure] = useState(null);
    const [selectedSubstructure, setSelectedSubstructure] = useState(null);
    const [selectedStructure2, setSelectedStructure2] = useState(null);
    const [selectedSubstructure2, setSelectedSubstructure2] = useState(null);
    const [selectedIncidences, setSelectedIncidences] = useState([]);
    const [selectedSystem, setSelectedSystem] = useState(null);
    const [problem, setProblem] = useState('');
    const [description, setDescription] = useState('');
    const [problemDescription, setProblemDescription] = useState('');
    const [isObservationsEnabled, setIsObservationsEnabled] = useState(false);
    const [observations, setObservations] = useState([]);
    const [isGenerating, setIsGenerating] = useState(false);
    const [isReadyToGenerate, setIsReadyToGenerate] = useState(false);
    const [isGenerated, setIsGenerated] = useState(false);
    const [isDrawingMode, setIsDrawingMode] = useState(false);
    const [arrowsByImage, setArrowsByImage] = useState({});
    const [currentArrow, setCurrentArrow] = useState(null);
    const [isDrawingArrow, setIsDrawingArrow] = useState(false);
    const [isEraserMode, setIsEraserMode] = useState(false);
    const canvasRef = useRef(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const navigate = useNavigate();

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const savedData2 = JSON.parse(localStorage.getItem('formData2'));
        if (savedData2) {
            setSelectedStructure(savedData2.selectedStructure || '');
            setSelectedSubstructure(savedData2.selectedSubstructure || '');
            setSelectedIncidences(savedData2.selectedIncidences || []);
            setDescription(savedData2.description || '');
            setSelectedSystem(savedData2.selectedSystem || '');
            setSelectedStructure2(savedData2.selectedStructure2 || '');
            setSelectedSubstructure2(savedData2.selectedSubstructure2 || '');
            setProblem(savedData2.problem || '');
            setProblemDescription(savedData2.problemDescription || '');
            setObservations(savedData2.observations || []);
        }
        setIsLoaded(true);
    }, []);



    useEffect(() => {
        if (isLoaded) {
            const formData2 = {
                selectedStructure,
                selectedSubstructure,
                selectedIncidences,
                description,
                selectedSystem,
                selectedStructure2,
                selectedSubstructure2,
                problem,
                problemDescription,
                observations,
            };
            localStorage.setItem('formData2', JSON.stringify(formData2));

        }
    }, [isLoaded, selectedStructure, selectedSubstructure, selectedIncidences, description, selectedSystem, selectedStructure2, selectedSubstructure2, problem, problemDescription, observations]);

    const toggleModal = () => {
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
    };

    // const handleSaveChanges = (editedData) => {
    //     // setDescription(editedData.solicitud);
    //     setObservations(editedData.observaciones.split('\n'));
    //     setVertexResponse(editedData.diagnostico);
    // };

    useEffect(() => {
        const savedImages = JSON.parse(localStorage.getItem('imagenes') || '[]');
        setImages(savedImages);
    }, []);

    useEffect(() => {
        const response = localStorage.getItem('vertexResponse') || '';
        setVertexResponse(response);
    }, []);
    useEffect(() => {
        if (isLoaded) {
            const storedVertexResponse = localStorage.getItem('vertexResponse');

            // Solo guardar si el valor ha cambiado para evitar bucles
            if (storedVertexResponse !== vertexResponse) {
                localStorage.setItem('vertexResponse', vertexResponse);
            }
        }
    }, [vertexResponse, isLoaded]);

    useEffect(() => {
        const handleVertexResponseChange = (event) => {
            if (event.key === 'vertexResponse') {
                setVertexResponse(event.newValue || '');
            }
        };
        window.addEventListener('vertexResponseChange', handleVertexResponseChange);

        return () => {
            window.removeEventListener('vertexResponseChange', handleVertexResponseChange);
        };
    }, []);



    const handleZoomIn = () => {
        setZoom(prevZoom => Math.min(prevZoom + 0.1, 3));
    };

    const handleZoomOut = () => {
        setZoom(prevZoom => Math.max(prevZoom - 0.1, 0.5));
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
        setDragging(true);
        setStartPosition({ x: e.clientX - position.x, y: e.clientY - position.y });
    };

    const handleMouseMove = (e) => {
        if (dragging) {
            const newX = e.clientX - startPosition.x;
            const newY = e.clientY - startPosition.y;

            const limitedX = Math.min(Math.max(newX, -((zoom - 1) * 400)), (zoom - 1) * 400);
            const limitedY = Math.min(Math.max(newY, -((zoom - 1) * 296)), (zoom - 1) * 296);

            setPosition({ x: limitedX, y: limitedY });
        }
    };

    const handleMouseUp = () => setDragging(false);

    const regenerateDiagnosis = async () => {
        if (regenerateCount >= 3) return;

        setIsGenerating(true);
        setVertexResponse('');

        setRegenerateCount(regenerateCount + 1);
        // const notasAgregadas = JSON.parse(localStorage.getItem('notasAgregadas') || '[]');
        // const textoCelda = localStorage.getItem('textoCelda') || '';
        // const notas = notasAgregadas.map(nota => `Tipo de Estudio: ${nota.tipoEstudio} Sector: ${nota.sector} Observacion: ${nota.hallazgo}`).join('\n');

        const formData2 = JSON.parse(localStorage.getItem('formData2') || '{}');

        const observations = formData2.observations || [];

        const prompt = `Observaciones: ${observations.join('\n')}`;

        const response = await askVertex(prompt);
        localStorage.setItem('vertexResponse', response.response);
        setVertexResponse(response.response);

        setIsGenerating(false);
        setIsGenerated(true);

    };

    useEffect(() => {
        if (selectedStructure && selectedSubstructure && selectedIncidences.length > 0 && observations.length > 0) {
            setIsReadyToGenerate(true);
        } else {
            setIsReadyToGenerate(false);
        }
    }, [selectedStructure, selectedSubstructure, selectedIncidences, observations]);

    useEffect(() => {
        if (description.length > 0) {
            setIsObservationsEnabled(true);
        } else {
            setIsObservationsEnabled(false);
        }
    }, [description]);

    const nuevoInforme = () => {
        localStorage.removeItem('formData2');
        localStorage.removeItem('formValues');
        localStorage.removeItem('imagenes');
        localStorage.removeItem('vertexResponse');
        localStorage.removeItem('regenerateCount');
        navigate('/dashboard')
    }
    const handleAddObservation = () => {
        if (selectedSystem && selectedStructure2 && selectedSubstructure2 && problem) {
            const observation = `${selectedSystem}, ${selectedStructure2}, ${selectedSubstructure2}, ${problem}${problemDescription ? ', ' + problemDescription : ''}.`.trim();

            setObservations(prevObservations => {
                // Mantén las observaciones existentes y filtra las vacías al agregar la nueva
                const cleanedObservations = prevObservations.filter(obs => obs.trim() !== '');
                return [...cleanedObservations, observation];
            });

            setProblem('');
            setProblemDescription('');
        } else {
            message.error('Por favor, complete todos los campos antes de agregar.');
        }
    };

    useEffect(() => {
        localStorage.setItem('regenerateCount', regenerateCount);
    }, [regenerateCount]);

    const handleDrawArrow = () => {
        setIsDrawingMode(!isDrawingMode);
        setIsEraserMode(false);
        setIsDrawingArrow(false);
        setCurrentArrow(null);
    };

    const handleEraser = () => {
        setIsEraserMode(!isEraserMode);
        setIsDrawingMode(false);
        setIsDrawingArrow(false);
        setCurrentArrow(null);
    };

    const handleCanvasClick = (e) => {
        if (!isDrawingMode && !isEraserMode) return;

        const rect = canvasRef.current.getBoundingClientRect();
        const x = (e.clientX - rect.left - position.x) / zoom;
        const y = (e.clientY - rect.top - position.y) / zoom;

        if (isDrawingMode) {
            if (!isDrawingArrow) {
                setCurrentArrow({ start: { x, y } });
                setIsDrawingArrow(true);
            } else {
                const newArrow = { ...currentArrow, end: { x, y } };
                setArrowsByImage(prevArrows => ({
                    ...prevArrows,
                    [selectedImage]: [...(prevArrows[selectedImage] || []), newArrow]
                }));
                setCurrentArrow(null);
                setIsDrawingArrow(false);
            }
        } else if (isEraserMode) {
            eraseArrowsNear(x, y);
        }
    };

    const handleCanvasMouseMove = (e) => {
        if (!isDrawingMode || !isDrawingArrow || !currentArrow) return;

        const rect = canvasRef.current.getBoundingClientRect();
        const x = (e.clientX - rect.left - position.x) / zoom;
        const y = (e.clientY - rect.top - position.y) / zoom;

        setCurrentArrow(prev => ({ ...prev, end: { x, y } }));
    };

    const eraseArrowsNear = (x, y) => {
        const eraserRadius = 10;
        setArrowsByImage(prevArrows => {
            const currentImageArrows = prevArrows[selectedImage] || [];
            const remainingArrows = currentImageArrows.filter(arrow => {
                return !isPointNearLine(x, y, arrow.start, arrow.end, eraserRadius);
            });
            return {
                ...prevArrows,
                [selectedImage]: remainingArrows
            };
        });
    };

    const isPointNearLine = (x, y, start, end, maxDistance) => {
        const A = x - start.x;
        const B = y - start.y;
        const C = end.x - start.x;
        const D = end.y - start.y;

        const dot = A * C + B * D;
        const lenSq = C * C + D * D;
        let param = -1;
        if (lenSq !== 0) {
            param = dot / lenSq;
        }

        let xx, yy;

        if (param < 0) {
            xx = start.x;
            yy = start.y;
        } else if (param > 1) {
            xx = end.x;
            yy = end.y;
        } else {
            xx = start.x + param * C;
            yy = start.y + param * D;
        }

        const dx = x - xx;
        const dy = y - yy;
        const distance = Math.sqrt(dx * dx + dy * dy);

        return distance <= maxDistance;
    };

    const drawArrows = useCallback(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const drawArrow = (start, end, color) => {
            const headlen = 10 / zoom;
            const dx = end.x - start.x;
            const dy = end.y - start.y;
            const angle = Math.atan2(dy, dx);

            ctx.beginPath();
            ctx.moveTo(start.x, start.y);
            ctx.lineTo(end.x, end.y);
            ctx.strokeStyle = color;
            ctx.lineWidth = 2 / zoom;
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(end.x, end.y);
            ctx.lineTo(end.x - headlen * Math.cos(angle - Math.PI / 6), end.y - headlen * Math.sin(angle - Math.PI / 6));
            ctx.lineTo(end.x - headlen * Math.cos(angle + Math.PI / 6), end.y - headlen * Math.sin(angle + Math.PI / 6));
            ctx.fillStyle = color;
            ctx.fill();
        };

        const currentImageArrows = arrowsByImage[selectedImage] || [];
        currentImageArrows.forEach((arrow, index) => {
            const start = {
                x: (arrow.start.x * zoom) + position.x,
                y: (arrow.start.y * zoom) + position.y
            };
            const end = {
                x: (arrow.end.x * zoom) + position.x,
                y: (arrow.end.y * zoom) + position.y
            };

            const color = index % 2 === 0 ? '#FF6F00' : '#00FF00';
            drawArrow(start, end, color);
        });

        if (currentArrow && currentArrow.end) {
            const start = {
                x: (currentArrow.start.x * zoom) + position.x,
                y: (currentArrow.start.y * zoom) + position.y
            };
            const end = {
                x: (currentArrow.end.x * zoom) + position.x,
                y: (currentArrow.end.y * zoom) + position.y
            };

            const color = currentImageArrows.length % 2 === 0 ? '#FFCC80' : '#28a745';
            drawArrow(start, end, color);
        }
    }, [arrowsByImage, zoom, position, currentArrow, selectedImage]);
    const handleContrastChange = (value) => {
        setContrast(value);
    };

    const handleInvertChange = (value) => {
        setInvert(value);
    };
    useEffect(() => {
        if (canvasRef.current) {
            drawArrows();
        }
    }, [drawArrows]);

    return (
        <div onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
            <Row className="content content2">
                <Col md={2} sm={24} xs={24} className="columna-izq">
                    {images.map((img, index) => (
                        <div key={index} className="image-thumbnail">
                            <img
                                src={img}
                                alt={`Thumbnail ${index}`}
                                onClick={() => {
                                    setSelectedImage(img);
                                    setPosition({ x: 0, y: 0 });
                                    setZoom(1);
                                }}
                                className="thumbnail-image"
                            />
                        </div>
                    ))}
                </Col>
                <Col md={16} sm={24} xs={24} className="columna-cen">
                    {selectedImage ? (
                        <div className="image-container">
                            <Row className="superior-col">
                                <Col>
                                    <Button icon={<ZoomInOutlined className="zoom" />} onClick={handleZoomIn} className="zoom" />
                                    <Button icon={<ZoomOutOutlined className="zoom" />} onClick={handleZoomOut} className="zoom" />
                                    <Button
                                        icon={<ArrowRightOutlined className="zoom2" />}
                                        onClick={handleDrawArrow}
                                        className={`arrows zoom2 ${isDrawingMode ? 'active' : ''}`}
                                        aria-label={isDrawingMode ? "Desactivar modo de dibujo de flechas" : "Activar modo de dibujo de flechas"}
                                    />
                                    <Button
                                        icon={<DeleteOutlined className="zoom2" />}
                                        onClick={handleEraser}
                                        className={`arrows zoom2 ${isEraserMode ? 'active' : ''}`}
                                        aria-label={isEraserMode ? "Desactivar goma de borrar" : "Activar goma de borrar"}
                                    />
                                </Col>
                                <Col>
                                    <Row>
                                        <Slider
                                            min={100}
                                            max={500}
                                            value={contrast}
                                            onChange={handleContrastChange}
                                            className="contrast-slider"
                                        />
                                        <Slider
                                            min={0}
                                            max={100}
                                            value={invert}
                                            onChange={handleInvertChange}
                                            className="invert-slider"
                                        />
                                    </Row>
                                </Col>
                            </Row>
                            <div
                                className="image-wrapper"
                                onMouseDown={handleMouseDown}
                                onClick={handleCanvasClick}
                                onMouseMove={handleCanvasMouseMove}
                                style={{
                                    cursor: isDrawingMode ? 'crosshair' : (isEraserMode ? 'cell' : (zoom > 1 ? (dragging ? 'grabbing' : 'grab') : 'default')),
                                    position: 'relative',
                                    overflow: 'hidden',
                                }}
                            >
                                <Image
                                    src={selectedImage}
                                    alt="Selected"
                                    preview={false}
                                    className="full-image no-select"
                                    style={{
                                        transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`,
                                        filter: `contrast(${contrast}%) invert(${invert}%)`,
                                    }}
                                />
                                <canvas
                                    ref={canvasRef}
                                    width={800}
                                    height={592}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        pointerEvents: 'none',
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <p className="default-text">Seleccione una imagen para verla en detalle</p>
                    )}
                </Col>

                <Col md={6} sm={24} xs={24} className="columna-der">
                    <Row justify={'center'} className="center">
                        <Col span={20}>
                            <Row className="input-row">
                                <Collapse
                                    defaultActiveKey={['1']}
                                    expandIconPosition="end"
                                    className='collapse'
                                >
                                    <Panel
                                        header={<span className="generating">Solicitud:</span>}
                                        key="1"
                                    >
                                        <Select
                                            value={selectedStructure || undefined}
                                            placeholder="Seleccionar estructura"
                                            onChange={value => {
                                                setSelectedStructure(value);
                                                setSelectedSubstructure(null);
                                                setSelectedIncidences([]);
                                            }}
                                            style={{ width: '100%', marginBottom: '10px', marginTop: '10px' }}
                                        >
                                            <Option value="estructura1">Estructura 1</Option>
                                            <Option value="estructura2">Estructura 2</Option>
                                        </Select>

                                        <Select
                                            placeholder="Seleccionar subestructura"
                                            value={selectedSubstructure || undefined}
                                            onChange={value => {
                                                setSelectedSubstructure(value);
                                                setSelectedIncidences([]);
                                                ;
                                            }}
                                            disabled={!selectedStructure}
                                            style={{ width: '100%', marginBottom: '10px' }}
                                        >
                                            <Option value="subestructura1">Subestructura 1</Option>
                                            <Option value="subestructura2">Subestructura 2</Option>
                                        </Select>

                                        <Select
                                            mode="tags"
                                            placeholder="Incidencias"
                                            onChange={values => {
                                                setSelectedIncidences(values);

                                            }}
                                            value={selectedIncidences}
                                            disabled={!selectedSubstructure}
                                            style={{ width: '100%', marginBottom: '10px' }}
                                        >
                                            <Option value="incidencia1">Incidencia 1</Option>
                                            <Option value="incidencia2">Incidencia 2</Option>
                                            <Option value="incidencia3">Otros</Option>
                                        </Select>

                                        <TextArea
                                            className="custom-textarea"
                                            value={description || undefined}
                                            placeholder="Descripción"
                                            onChange={e => setDescription(e.target.value)}
                                            style={{ width: '100%' }}
                                        />
                                    </Panel>
                                </Collapse>
                            </Row>
                            <hr />
                            <Row className="input-row">
                                <Collapse defaultActiveKey={[]} expandIconPosition="end">
                                    <Panel
                                        header={<span className="generating">Observaciones:</span>}
                                        key="1"
                                        collapsible={isObservationsEnabled ? 'header' : 'disabled'}
                                    >
                                        <Select
                                            placeholder="Sistema"
                                            value={selectedSystem || undefined}
                                            onChange={value => {
                                                setSelectedSystem(value);
                                                setSelectedStructure2(null);
                                                setSelectedSubstructure2(null);
                                                setProblem('');
                                                setProblemDescription('');
                                            }}
                                            style={{ width: '100%', marginBottom: '10px', marginTop: '10px' }}
                                        >
                                            <Option value="sistema1">Sistema 1</Option>
                                            <Option value="sistema2">Sistema 2</Option>
                                        </Select>

                                        <Select
                                            placeholder="Seleccionar estructura"
                                            value={selectedStructure2 || undefined}
                                            onChange={value => {
                                                setSelectedStructure2(value);
                                                setSelectedSubstructure2(null);
                                                setProblem('');
                                                setProblemDescription('');
                                            }}
                                            style={{ width: '100%', marginBottom: '10px' }}
                                            disabled={!selectedSystem}
                                        >
                                            <Option value="estructura1">Estructura 1</Option>
                                            <Option value="estructura2">Estructura 2</Option>
                                        </Select>

                                        <Select
                                            placeholder="Seleccionar subestructura"
                                            value={selectedSubstructure2 || undefined}
                                            onChange={value => {
                                                setSelectedSubstructure2(value);
                                                setProblem('');
                                                setProblemDescription('');
                                            }}
                                            style={{ width: '100%', marginBottom: '10px' }}
                                            disabled={!selectedStructure2}
                                        >
                                            <Option value="subestructura1">Subestructura 1</Option>
                                            <Option value="subestructura2">Subestructura 2</Option>
                                        </Select>

                                        <TextArea
                                            placeholder="Problema"
                                            value={problem || undefined}
                                            onChange={e => setProblem(e.target.value)}
                                            className="custom-textarea"
                                            disabled={!selectedSubstructure2}
                                            style={{ width: '100%', marginBottom: '10px' }}
                                        />

                                        <TextArea
                                            placeholder="Descripción"
                                            value={problemDescription || undefined}
                                            className="custom-textarea"
                                            onChange={e => setProblemDescription(e.target.value)}
                                            disabled={!problem}
                                            style={{ width: '100%', marginBottom: '10px' }}
                                        />

                                        <Button icon={<PlusCircleOutlined />} iconPosition={position} onClick={handleAddObservation} style={{ float: 'right' }}>
                                            Agregar
                                        </Button>

                                        <TextArea
                                            placeholder="Observaciones agregadas"
                                            value={observations.join('\n')} // Muestra las observaciones separadas por saltos de línea
                                            rows={4}
                                            className="custom-textarea"
                                            onChange={e => {
                                                setObservations(e.target.value.split('\n')); // Permite escribir normalmente con saltos de línea
                                            }}
                                            style={{ marginTop: '20px' }}
                                        />
                                    </Panel>
                                </Collapse>
                            </Row>
                            <hr />
                            <Row className="input-row">
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                    {!isGenerated ? (
                                        <>
                                            <span className="generating diagnostico">
                                                {isGenerating ? 'Generando diagnóstico' : 'Diagnóstico'}
                                            </span>
                                            {!isGenerating && !isGenerated && !vertexResponse && (
                                                <Button
                                                    className="generate-button"
                                                    onClick={regenerateDiagnosis}
                                                    disabled={!isReadyToGenerate}
                                                >
                                                    Generar
                                                </Button>
                                            )}
                                            {isGenerating && (
                                                <div className="dots" style={{ marginLeft: '10px' }}>
                                                    <span style={{ '--i': 1 }}>.</span>
                                                    <span style={{ '--i': 2 }}>.</span>
                                                    <span style={{ '--i': 3 }}>.</span>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <span className="generating spaces">
                                            Diagnóstico generado:
                                        </span>
                                    )}
                                </div>

                                {!vertexResponse && isGenerating ? (
                                    <div className="skeleton-loader"></div>
                                ) : (
                                    vertexResponse && (
                                        <TextArea
                                            rows={6}
                                            value={vertexResponse}
                                            onChange={(e) => setVertexResponse(e.target.value)}
                                        />
                                    )
                                )}

                                {vertexResponse && (
                                    <Button
                                        className="regenerate-button"
                                        onClick={regenerateDiagnosis}
                                        disabled={regenerateCount >= 3}
                                    >
                                        Regenerar Diagnóstico ({3 - regenerateCount} restantes)
                                    </Button>
                                )}
                            </Row>

                            <Row className="center">
                                <Button className="button4" onClick={() => navigate(-1)}>
                                    Volver
                                </Button>
                                <Popconfirm
                                    title="¿Estás seguro de que quieres iniciar un nuevo informe?"
                                    description="Perderás todos los datos cargados."
                                    okText="Sí, continuar"
                                    cancelText="Cancelar"
                                    onConfirm={nuevoInforme}
                                    okButtonProps={{ danger: true }}
                                >
                                    <Button className="button5">
                                        Nuevo informe
                                    </Button>
                                </Popconfirm>
                            </Row>

                            <Row className="center">
                                <Button
                                    className="generate-button"
                                    onClick={toggleModal}
                                    disabled={!vertexResponse}
                                >
                                    Vista previa de informe
                                </Button>
                                {/* <Button
                                    className="generate-button"
                                    onClick={() => generatePDF(vertexResponse, images)}
                                    disabled={!vertexResponse}
                                >
                                    <DownloadOutlined />
                                    Descargar Informe
                                </Button> */}
                            </Row>
                        </Col>
                    </Row>
                </Col>

            </Row>
            <InformeModal
                isVisible={isModalVisible}
                onClose={handleModalClose}
                solicitud={`${selectedStructure}, ${selectedSubstructure}, ${selectedIncidences.join(', ')}\n${description}`}
                observaciones={observations.join('\n')}
                diagnostico={vertexResponse}
                // onSave={handleSaveChanges}
                images={images}
            />
        </div>
    );
};

export default Analisis;