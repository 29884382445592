import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Col, DatePicker, Input, message, Modal, Row, Select } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import '../assets/styles/AnalizarPaciente.css';
import { DeleteOutlined } from '@ant-design/icons';
import imageCompression from 'browser-image-compression';
import moment from 'moment';

const { Option } = Select;

const AnalizarPaciente = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [imagenes, setImagenes] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [breedSuggestions, setBreedSuggestions] = useState([]);
    const [loadingBreeds, setLoadingBreeds] = useState(false);

    // Initialize formValues state
    const [formValues, setFormValues] = useState(() => {
        const savedFormValues = JSON.parse(localStorage.getItem('formValues') || '{}');
        return {
            tutor: savedFormValues.tutor || '',
            tutor_email: savedFormValues.tutor_email || '',
            profesional_derivante: savedFormValues.profesional_derivante || '',
            profesional_derivante_email: savedFormValues.profesional_derivante_email || '',
            nombreAnimal: savedFormValues.nombreAnimal || '',
            edad: savedFormValues.edad || '',
            edadUnidad: savedFormValues.edadUnidad || 'años',
            sexo: savedFormValues.sexo || '',
            especie: savedFormValues.especie || null,
            raza: savedFormValues.raza || '',
            solicitud: savedFormValues.solicitud || '',
            fecha: savedFormValues.fecha ? moment(savedFormValues.fecha) : moment(),
            tipo_estudio: savedFormValues.tipo_estudio || '',
        };
    });

    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('access_token');

        if (token) {
            // Almacenar el token en localStorage
            localStorage.setItem('access_token', token);
            // Remover el token de la URL
            navigate(location.pathname, { replace: true });
        }
    }, [location, navigate]);


    // Load images from localStorage when component mounts
    useEffect(() => {
        const savedImages = JSON.parse(localStorage.getItem('imagenes') || '[]');
        setImagenes(savedImages);
    }, []);

    // Save form data to localStorage whenever it changes
    useEffect(() => {
        const valuesToSave = {
            ...formValues,
            fecha: formValues.fecha ? formValues.fecha.toISOString() : null, // Guardar fecha como string ISO
        };
        localStorage.setItem('formValues', JSON.stringify(valuesToSave));
    }, [formValues]);

    const fetchBreeds = async (query) => {
        if (!query) return setBreedSuggestions([]);

        setLoadingBreeds(true);
        try {
            const isDog = formValues.especie === 'Canino';
            const breedEndpoint = isDog
                ? 'https://api.thedogapi.com/v1/breeds'
                : 'https://api.thecatapi.com/v1/breeds';

            const response = await axios.get(breedEndpoint);

            const filteredBreeds = response.data.filter(breed =>
                breed.name.toLowerCase().includes(query.toLowerCase())
            );

            const breedTranslations = await Promise.all(filteredBreeds.map(async (breed) => {
                try {
                    const wikiResponse = await axios.get(`https://es.wikipedia.org/w/api.php`, {
                        params: {
                            action: 'query',
                            list: 'search',
                            srsearch: breed.name,
                            format: 'json',
                            origin: '*'
                        }
                    });

                    const pageTitle = wikiResponse.data.query.search[0]?.title || breed.name;

                    // Usar la API adecuada para obtener la imagen de la raza
                    const imageEndpoint = isDog
                        ? `https://api.thedogapi.com/v1/images/search`
                        : `https://api.thecatapi.com/v1/images/search`;

                    const imageResponse = await axios.get(imageEndpoint, {
                        params: isDog ? { breed_ids: breed.id } : { breed_ids: breed.id }
                    });

                    const breedImageUrl = imageResponse.data[0]?.url || '';

                    return {
                        ...breed,
                        translatedName: pageTitle,
                        breedImageUrl
                    };
                } catch (error) {
                    console.error(`Error buscando la traducción de ${breed.name}:`, error);
                    return {
                        ...breed,
                        translatedName: breed.name,
                        breedImageUrl: ''
                    };
                }
            }));

            setBreedSuggestions(breedTranslations);
        } catch (error) {
            console.error('Error fetching breeds:', error);
        } finally {
            setLoadingBreeds(false);
        }
    };

    const handleRazaInputChange = (e) => {
        const { value } = e.target;

        // Validar raza: no puede tener números ni símbolos raros
        if (!/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/.test(value)) {
            return;
        }

        setFormValues((prevValues) => ({
            ...prevValues,
            raza: value,
        }));

        fetchBreeds(value);
    };

    const handleBreedSelect = (breedName) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            raza: breedName,
        }));
        setBreedSuggestions([]);
    };


    const handleAnalizar = async () => {
        const errors = {};

        // Validaciones generales
        if (!formValues.tutor) errors.tutor = true;
        if (!formValues.nombreAnimal) errors.nombreAnimal = true;
        if (!formValues.edad) errors.edad = true;
        if (!formValues.sexo) errors.sexo = true;
        if (!formValues.edadUnidad) errors.edadUnidad = true;
        if (!formValues.especie) errors.especie = true;
        if (!formValues.tipo_estudio) errors.tipo_estudio = true;
        if (!formValues.fecha) errors.fecha = true;

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (formValues.profesional_derivante_email && !emailRegex.test(formValues.profesional_derivante_email)) {
            errors.profesional_derivante_email = true;
        }
        if (formValues.tutor_email && !emailRegex.test(formValues.tutor_email)) {
            errors.tutor_email = true;
        }

        // Validación de las imágenes
        if (imagenes.length === 0) {
            message.error('Debes subir al menos una imagen.');
            return;
        }

        setFormErrors(errors);

        // Verifica si hay errores en el formulario
        if (Object.keys(errors).length === 0) {
            navigate('/analisis');
        } else {
            message.error('Por favor, completa todos los campos obligatorios y revisa los emails antes de continuar.');
        }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Validar edad: no puede tener más de 3 dígitos
        if (name === 'edad' && !/^\d{0,3}$/.test(value)) {
            return; // No permite que la edad tenga más de 3 dígitos
        }

        // Validar campos que no pueden tener números ni símbolos raros
        const textFields = ['nombreAnimal', 'raza', 'tutor', 'profesional_derivante'];
        if (textFields.includes(name) && !/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/.test(value)) {
            return; // No permite números ni símbolos raros
        }

        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));

        // Limpiar errores de validación al cambiar los valores
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));
    };


    const handleEspecieChange = (value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            especie: value,
            raza: '', // Limpiar raza al cambiar de especie
        }));

        setFormErrors({
            ...formErrors,
            especie: false,
        });

        // Llamar a la función de búsqueda de razas después de cambiar la especie
        if (value) fetchBreeds(''); // Resetear las sugerencias de raza
    };

    const handleEspecialistaChange = (value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            profesional_derivante: value,
        }));

        setFormErrors({
            ...formErrors,
            profesional_derivante: false,
        });
    };


    const handleImagenesChange = async (event) => {
        const files = Array.from(event.target.files);

        // Filtrar archivos inválidos
        const invalidFiles = files.filter(file =>
            !file.type.startsWith('image/') || /\.(webp|svg|gif)$/i.test(file.name)
        );

        if (invalidFiles.length > 0) {
            message.error('Solo se permiten imágenes en formato JPG, JPEG o PNG.');
            return; // Detener la ejecución si hay archivos no válidos
        }

        const validFiles = files.filter(file =>
            file.type.startsWith('image/') && !/\.(webp|svg|gif)$/i.test(file.name)
        );

        // Opciones de compresión
        const options = {
            maxSizeMB: 0.2, // Tamaño máximo en MB por imagen (ajustable)
            maxWidthOrHeight: 800, // Dimensión máxima (ajustable)
            useWebWorker: true, // Para mejorar el rendimiento
        };

        const compressedImagePromises = validFiles.map(async (file) => {
            try {
                const compressedFile = await imageCompression(file, options); // Comprime la imagen
                return await readFileAsDataURL(compressedFile); // Convierte a base64
            } catch (error) {
                console.error('Error al comprimir la imagen:', error);
                message.error('Error al comprimir una imagen.');
            }
        });

        // Leer archivos comprimidos
        Promise.all(compressedImagePromises)
            .then((compressedImages) => {
                const updatedImages = [...imagenes, ...compressedImages].slice(0, 70); // Máximo 70 imágenes
                setImagenes(updatedImages);
                localStorage.setItem('imagenes', JSON.stringify(updatedImages));
            })
            .catch((error) => {
                message.error('Error al procesar las imágenes.');
                console.error('Error general:', error);
            });
    };

    // Función auxiliar para leer archivos como DataURL
    const readFileAsDataURL = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
        });
    };


    const handleRemoveImage = (index) => {
        const updatedImages = imagenes.filter((_, i) => i !== index);
        setImagenes(updatedImages);
        localStorage.setItem('imagenes', JSON.stringify(updatedImages));
    };

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedImage(null);
    };
    const handleAgeUnitChange = (value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            edadUnidad: value,
        }));
    };

    return (
        <div className="body">
            <Row className="content">
                <Col lg={13} sm={24} xs={24} className="leftcolumn">
                    <Col md={13} sm={20} xs={20}>
                        <Row className="logo-container">
                            <p className="bluetitle decrease analizar-title">Analizar Paciente</p>
                        </Row>
                        <Row justify={'space-between'}>
                            <Col className="custom-col-11-6">
                                <DatePicker
                                    placeholder="Fecha *"
                                    className={`loginInput2 ${formErrors.fecha ? 'input-error' : ''}`}
                                    value={formValues.fecha}
                                    onChange={(date, dateString) => {
                                        setFormValues((prevValues) => ({
                                            ...prevValues,
                                            fecha: date, // Almacena el valor de tipo moment
                                        }));
                                        setFormErrors((prevErrors) => ({
                                            ...prevErrors,
                                            fecha: false,
                                        }));
                                    }}
                                    style={{ width: '100%' }}
                                    format="DD/MM/YYYY" // Formato de fecha en español
                                />

                            </Col>

                            <Col className="custom-col-11-6">
                                <Input
                                    placeholder="Nombre Animal *"
                                    className={`loginInput2 ${formErrors.nombreAnimal ? 'input-error' : ''}`}
                                    name="nombreAnimal"
                                    value={formValues.nombreAnimal}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Row>
                        <Row justify={'space-between'}>
                            <Col className="custom-col-11-6-edad">
                                <Input
                                    placeholder="Edad *"
                                    className={`loginInput2 ${formErrors.edad ? 'input-error' : ''}`}
                                    name="edad"
                                    value={formValues.edad}
                                    onChange={handleInputChange}
                                />
                            </Col>
                            <Col className="custom-col-11-6-unidad">
                                <Select
                                    className={`loginInput2 ${formErrors.edadUnidad ? 'input-error' : ''}`}
                                    placeholder="Unidad de Edad"
                                    value={formValues.edadUnidad || 'años'}
                                    onChange={handleAgeUnitChange}
                                    style={{ textAlign: 'left' }}
                                    allowClear
                                >
                                    <Option value="días">Días</Option>
                                    <Option value="meses">Meses</Option>
                                    <Option value="años">Años</Option>
                                </Select>
                            </Col>
                            <Col className="custom-col-11-6">
                                <Select
                                    className={`loginInput2 ${formErrors.especie ? 'input-error' : ''}`}
                                    placeholder="Especie *"
                                    value={formValues.especie || undefined}
                                    onChange={handleEspecieChange}
                                    allowClear
                                    style={{ textAlign: 'left' }}
                                >
                                    <Option value="Canino">Canino</Option>
                                    <Option value="Felino">Felino</Option>
                                </Select>
                            </Col>
                            <Col className="custom-col-11-6" style={{ position: 'relative' }}>
                                <Input
                                    placeholder="Raza"
                                    className={`loginInput2 ${formErrors.raza ? 'input-error' : ''}`}
                                    name="raza"
                                    value={formValues.raza}
                                    onChange={handleRazaInputChange}
                                    disabled={formValues.especie == null}
                                />

                                {/* Suggestions List */}
                                {breedSuggestions.length > 0 && (
                                    <ul className="suggestions-list">
                                        {loadingBreeds ? (
                                            <li>Cargando...</li>
                                        ) : (
                                            breedSuggestions.map((breed) => (
                                                <li
                                                    key={breed.id}
                                                    onClick={() => handleBreedSelect(breed.translatedName || breed.name)}
                                                    className="suggestion-item"
                                                >
                                                    {/* Imagen a la izquierda */}
                                                    <div className="suggestion-image-container">
                                                        {breed.breedImageUrl ? (
                                                            <img
                                                                src={breed.breedImageUrl}
                                                                alt={breed.translatedName || breed.name}
                                                                className="suggestion-image"
                                                            />
                                                        ) : (
                                                            <span className="no-image">:(</span>
                                                        )}
                                                    </div>

                                                    {/* nombreAnimal a la derecha */}
                                                    <div className="suggestion-name">
                                                        {breed.translatedName || breed.name}
                                                    </div>
                                                </li>
                                            ))
                                        )}
                                    </ul>
                                )}
                            </Col>
                            <Col className="custom-col-11-6">
                                <Select
                                    className={`loginInput2 ${formErrors.sexo ? 'input-error' : ''}`}
                                    placeholder="Sexo *"
                                    value={formValues.sexo || undefined}
                                    onChange={(value) => setFormValues((prevValues) => ({
                                        ...prevValues,
                                        sexo: value,
                                    }))}
                                    allowClear
                                    style={{ textAlign: 'left' }}
                                >
                                    <Option value="Macho">Macho</Option>
                                    <Option value="Hembra">Hembra</Option>
                                    <Option value="Macho-Castrado">Macho-Castrado</Option>
                                    <Option value="Hembra-Castrada">Hembra-Castrada</Option>
                                </Select>
                            </Col>
                        </Row>
                        <Row justify={'space-between'}>

                            <Col className="custom-col-11-6">
                                <Select
                                    className={`loginInput2 ${formErrors.profesional_derivante ? 'input-error' : ''}`}
                                    placeholder="Profesional derivante"
                                    value={formValues.profesional_derivante || undefined}
                                    onChange={handleEspecialistaChange}
                                    allowClear
                                    style={{ textAlign: 'left' }}
                                >
                                    <Option value="Juan">Juan</Option>
                                    <Option value="Jose">Jose</Option>
                                </Select>
                            </Col>
                            <Col className="custom-col-11-6">
                                <Input
                                    placeholder="Email del profesional"
                                    className={`loginInput2 ${formErrors.profesional_derivante_email ? 'input-error' : ''}`}
                                    name="profesional_derivante_email"
                                    value={formValues.profesional_derivante_email}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Row>
                        <Row justify={'space-between'}>
                            <Col className="custom-col-11-6">
                                <Input
                                    placeholder="Tutor *"
                                    className={`loginInput2 ${formErrors.tutor ? 'input-error' : ''}`}
                                    name="tutor"
                                    value={formValues.tutor}
                                    onChange={handleInputChange}
                                />
                            </Col>
                            <Col className="custom-col-11-6">
                                <Input
                                    placeholder="Email del tutor"
                                    className={`loginInput2 ${formErrors.tutor_email ? 'input-error' : ''}`}
                                    name="tutor_email"
                                    value={formValues.tutor_email}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            {/* <Input.TextArea
                                placeholder="Solicitud *"
                                className={`loginInput2 ${formErrors.solicitud ? 'input-error' : ''}`}
                                name="solicitud"
                                value={formValues.solicitud}
                                onChange={handleInputChange}
                                autoSize={{ minRows: 1, maxRows: 3 }}
                            /> */}
                            <Select
                                className={`loginInput2 ${formErrors.tipo_estudio ? 'input-error' : ''}`}
                                placeholder="Tipo de estudio *"
                                value={formValues.tipo_estudio || undefined}
                                onChange={(value) => setFormValues((prevValues) => ({
                                    ...prevValues,
                                    tipo_estudio: value,
                                }))}
                                allowClear
                                style={{ textAlign: 'left' }}
                            >
                                <Option value="Radiografia">Radiografía</Option>
                                <Option value="Ecografía">Ecografía</Option>
                            </Select>
                        </Row>
                        <Row>
                            <Button className="analizar-button">
                                <input
                                    id="upload-input"
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={handleImagenesChange}
                                />
                                <label htmlFor="upload-input" style={{ cursor: 'pointer' }}>Agregar imágenes</label>
                            </Button>
                        </Row>
                        <div>
                            <button className="button3" onClick={() => navigate('/')}>
                                Volver
                            </button>
                            <button className="button2 espaciar" onClick={handleAnalizar}>
                                Continuar
                            </button>
                        </div>
                    </Col>
                </Col>
                <Col lg={11} sm={24} xs={24} className="rightcolumn">
                    <div className="rightside-container4">
                        <div className="image-grid">
                            {imagenes.map((imagen, index) => (
                                <div key={index} className="image-preview">
                                    <img
                                        src={imagen}
                                        alt={`Imagen ${index + 1}`}
                                        className="uploaded-image"
                                        onClick={() => handleImageClick(imagen)}
                                    />
                                    <Button className="remove-button"
                                        onClick={() => handleRemoveImage(index)}><DeleteOutlined /></Button>
                                </div>
                            ))}
                        </div>
                    </div>
                </Col>
            </Row>
            <Modal visible={isModalVisible} footer={null} onCancel={handleModalClose}>
                <img src={selectedImage} alt="Imagen ampliada" style={{ width: '100%' }} />
            </Modal>
        </div>
    );
};

export default AnalizarPaciente;
